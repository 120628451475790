.date-style {
    color: rgb(226, 228, 232);
    font-family: monospace;
}

.vertical-timeline-element-content {
    box-shadow: 0 3px 0 rgb(32, 36, 40);
}

.vertical-timeline-element-icon svg {
    width: 42px;
    height: 42px;
    margin-left: -21px;
    margin-top: -21px;
}